import React, { Fragment } from "react";
import dashedLine from "../../assets/img/icons/dashed-line.svg";
// import AOS from "aos";
// import "aos/dist/aos.css";
import { isMobile, isTablet } from "react-device-detect";

const ImageSteps = ({ steps }) => {
  // useEffect(() => {
  //   AOS.init();
  //   AOS.refresh();
  // });
  return (
    <Fragment>
      <div className='column image-steps top-pad'>
        <img className='dashed-line' src={dashedLine} alt='Line' />
        {steps.sort().map((el, index) => (
          <Fragment key={index}>
            <div
              className='image-container'
              // data-aos='fade-right'
              data-aos={`${isMobile || isTablet ? "fade-up" : "fade-right"}`}
              data-aos-delay={index * 200}
              key={index}
            >
              <div className='image'>
                <img src={el.img} alt={el.name} />
              </div>
              <h3 className='main-subtitle'>{el.name}</h3>
              {el.instructions && (
                <div className='instructions' data-aos='zoom-in'>
                  {el.instructions}
                </div>
              )}
            </div>
          </Fragment>
        ))}
      </div>
    </Fragment>
  );
};

export default ImageSteps;
